@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/themes/camfil/imports";


$name: '.Footer';

#{$name} {
    background-color: $footer-bg;
    position: relative; 

    &-backToTop {
        position: absolute;
        top: -15px;
        left: 50%;
        padding: .5rem 1rem;
        background-color: $footer-bg;
        transform: translateX(-50%);
        box-shadow: 0 -3px 4px -2px rgba($black, .2);
        font-family: $headings-font-family;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: -.05em;
        border-radius: 10rem;
        color: $primary;
    }

    &-backToTopIcon {
        height: 8px;
        width: 12px;
        @include icon-chevron-down($primary);
        display: inline-block;
        background-size: contain;
        margin-right: .5rem;
        transform: rotate(180deg);
        background-position: 50%;
        background-repeat: no-repeat;
    }

    &-upper {
        padding: 40px 0;

        strong, b, h1, h2, h3, h4, h5, h6 { //From RTE, else follow SUIT CSS naming convention
            font-family: $headings-font-family;
        }
    }

    &-lower {
        background-color: $primary;
        color: $white;


        &Inner {
            padding-top: 15px;
            padding-bottom: 15px;
            @include icon-wing(#3f8564);
            background-repeat: no-repeat;
            background-size: 200px 100px;
            min-height: 100px;
        }

        a { //From RTE, else follow SUIT CSS naming convention
            color: $white;
        }
    }

    ul { //From RTE, else follow SUIT CSS naming convention
        list-style-type: none;
        padding: 0;
    }
}
